import React from "react";
import { Link } from "gatsby";
import {
    Box,
    Heading,
    Center,
    Img,
    Spinner,
    Flex,
    Spacer
} from "@chakra-ui/react"
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import { useHasMounted } from "../components/hooks";

import * as utils from "../utils/utility_funcs";

const Slider = (props) => {
    const hasMounted = useHasMounted();
    var sliders = props.nodes;

    // Sort by order
    sliders.sort((a, b) => a.order - b.order);
    
    if (!hasMounted || !utils.isBrowser()) {
        return <Center key="splide" w="100%"><Spinner size="lg" /></Center>
    }
    return (
        <Splide
            key="splide"
            options={{
                type         : 'loop',
                interval     : 5000,
                gap          : '5rem',
                autoplay     : true,
                pauseOnHover : true,
                resetProgress: false,
                arrows       : 'slider',
            }}
            hasSliderWrapper
            // hasAutoplayProgress
        >
            {sliders.map(slider =>
            <SplideSlide key={"si_" + slider.sliderImage.contentful_id}>
                <Center w="100%" direction="column">
                    <Link to={slider.link.name ? "/products/" + utils.removeWS(slider.link.name) : 
                        (slider.subCatLink ? "/categories/" + utils.removeWS(slider.link.categoryName) + "#" + utils.removeWS(slider.link.subCategoryName) : 
                            "/categories/" + utils.removeWS(slider.link.categoryName))}>
                            <Flex direction="column">
                                <Img 
                                    align="center"
                                    src={utils.isMobile() ? slider.sliderImageMobile.file.url : slider.sliderImage.file.url}
                                    alt={"Slider Image " + slider.link.name || slider.link.categoryName}
                                />
                                <Spacer />
                                <Box
                                    mt={1}
                                    mb={5}
                                    align="center"
                                    fontWeight="bold"
                                    lineHeight="tight"
                                    isTruncated
                                >
                                    <Heading size={utils.isMobile() ? "sm" : "md"}>
                                        {slider.caption || ""}
                                    </Heading>
                                </Box>
                            </Flex>
                    </Link>
                </Center>
            </SplideSlide>
            )}
        </Splide>
    )
}

export default Slider;