import React from "react";
import {
    Box,
    Icon,
    Heading,
} from "@chakra-ui/react";
import { graphql, Link } from "gatsby";
import {
    Paginator,
    Container,
    Previous,
    Next,
    PageGroup,
    usePaginator
} from "chakra-paginator";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import SEO from "../components/seo";
import NavBar from "../components/navbar";
import ProductGrid from "../components/products";
import Slider from "../components/slider";
import Footer from "../components/footer";

import { useHasMounted } from "../components/hooks";

import getFirebase from "../utils/firebase";
import { vitalizeConstants } from "../utils/constants";
import UserMgr from "../utils/user_manager";
const UMObj = UserMgr.getInstance();


// Adding successful transaction to analytics and order history
const addTransactionHistory = (fsModule, fs) => {
    console.log(fsModule, fs);
    const tr = UMObj.GetTransaction();
    const currUser = UMObj.GetCurrentUser();
    const date = new Date();

    // If local storage was cleared
    if (!tr || !tr.items) {
        console.log("TRANSACTION/ORDER HISTORY LOGGING FAIL");
        return;
    }
    
    if (currUser) {
        UMObj.SetTransaction(null);
        UMObj.AnaLog("purchase", tr);

        //console.log(fsModule, fs, tr.items);
        console.log(tr.items);

        // Add transaction items to orders entry in DB
        const usersRef = fsModule.collection(fs, "users");
        const queryUID = fsModule.query(usersRef,
            fsModule.where("uid", "==", currUser.uid),
        );
            
        fsModule.getDocs(queryUID)
        .then(snaps => {
            if (!snaps.empty) {
                //const oldOrders = snaps.docs[0].data().orders;
                const newOrder = {
                    orderDate: date.toDateString(),
                    orderTime: date.toTimeString(),
                    paymentMethod: tr.paymentMethod,
                    specialInstruction: (tr.specialInstr && tr.specialInstr !== "") ? tr.specialInstr : "NONE",
                    items: tr.items
                }
                // console.log("OLD: ", oldOrders);
                console.log("NEW: ", newOrder);
                const docRef = fsModule.doc(fs, "users", currUser.uid);
                //console.log(userRef);
                fsModule.updateDoc(docRef, {
                    orders: fsModule.arrayUnion(newOrder)
                })
            }
        })
    }
    else console.log("ERROR: USER IS NULL");
}

const IndexPage = (props) => {
    // Set up GraphQL queries
    const queryGraphQLProducts = props.data.allContentfulProduct.nodes;
    const queryGraphQLCategories = props.data.allContentfulCategory.distinct;
    const queryGraphQLImageSlider = props.data.allContentfulSlider.nodes;

    //console.log(JSON.stringify(props), null, 2)
    // Paginator options
    const pageSize = 20;
    const pagesQuantity = Math.floor(queryGraphQLProducts.length / pageSize);
    const { currentPage, setCurrentPage } = usePaginator({
        initialState: { 
            pageSize: pageSize,
            currentPage: 1,
        }
    });

    // Paginator constants
    const outerLimit = 2;
    const innerLimit = 2;

    // Paginator styles
    const baseStyles = {
        w: 7,
        fontSize: "sm"
    };
    const normalStyles = {
        ...baseStyles,
        _hover: {
            bg: "green.300"
        },
        bg: "gray.300"
    };
    const activeStyles = {
        ...baseStyles,
        _hover: {
            bg: "blue.300"
        },
        bg: "green.300"
    };
    const separatorStyles = {
        w: 7,
        bg: "green.200"
    };

    // If local storage is empty we need to initialize UM from GraphQL
    const hasMounted = useHasMounted();
    if (hasMounted) {
        UMObj.Init(queryGraphQLProducts, queryGraphQLCategories);

        var fbImportPromises = [];
        fbImportPromises.push(import("firebase/app"));
        fbImportPromises.push(import("firebase/auth"));
        fbImportPromises.push(import("firebase/analytics"));
        fbImportPromises.push(import("firebase/performance"));
        fbImportPromises.push(import("firebase/firestore"));
      
        Promise.all(fbImportPromises)
        .then(([fbAppModule, fbAuthModule, fbAnalyticsModule, fbPerfModule, fbFSModule]) => {
            const fb = getFirebase(fbAppModule);
            const fbApp = fb.getApp();
            const auth = fbAuthModule.getAuth(fbApp);
            fbAuthModule.useDeviceLanguage(auth);
            fbAuthModule.onAuthStateChanged(auth, UMObj.AuthListener);
            fbAuthModule.onIdTokenChanged(auth, UMObj.AuthListener);
            const analytics = fbAnalyticsModule.getAnalytics(fbApp);
            UMObj.SetAnalytics(analytics, fbAnalyticsModule);
            const perf = fbPerfModule.getPerformance(fbApp);
            const fs = fbFSModule.getFirestore(fbApp);

            return([fbFSModule, fs]);
        })
        .then(([fbFSModule, fs]) => {
            const searchParams = new URLSearchParams(props.location.search);
            var dName = "";

            if (searchParams.has("status")) {
                const status = searchParams.get("status");
                const currUser = UMObj.GetCurrentUser();
                switch (status) {
                    // Redirected from PG or CC checkout
                    case "payment_success":
                        addTransactionHistory(fbFSModule, fs);
                        if (!currUser) break;
                        dName = currUser ? currUser.displayName : "";

                        toast.success(`Thank you for your order ${currUser.displayName}! 🎉 \nIt is currently begin processed.`, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        break;

                    // Redirected from PG checkout
                    case "payment_cancel":
                        const PGCancelMsg = () => <Box>Payment cancelled. Please <AnchorLink to="/#bot"><u>contact us</u></AnchorLink> for any assistance.</Box>;
                        toast.warn(PGCancelMsg, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        break;

                    // Redirected from login/register
                    case "signin_success":
                        if (!currUser) break;
                        dName = currUser ? currUser.displayName : "";
                        const firstTimeUser = currUser.metadata.creationTime === currUser.metadata.lastSignInTime;

                        const SignInSuccessMsg = () => <Box>Welcome <b>{dName}</b>!</Box>;
                        toast.success(SignInSuccessMsg, {
                            position: "bottom-center",
                            autoClose: 10000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        
                        // First time user
                        if (firstTimeUser) {
                            const FirstTimeMsg = () => <Box>Looks like this is your first time with us. Please fill out your info in <Link to="customer/profile"><u>Profile</u></Link></Box>;
                            toast.info(FirstTimeMsg, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                        break;
            
                    default: break;
                }
            }
        })
    }

    return (
        <>
        <SEO categories={queryGraphQLCategories} />
        <Box as='main' bg='white'>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Box 
                position="sticky"
                top="0"
                margin={0}
                zIndex={vitalizeConstants.Z_NAVBAR}
            > 
                <NavBar />
            </Box>
            <Box px={{base: "2", md: "6"}} py={6}>
                <Slider nodes={queryGraphQLImageSlider} />
            </Box>
            <Heading mt={4} ml={3}>All Products</Heading>
            <Paginator
                innerLimit={innerLimit}
                outerLimit={outerLimit}        
                pagesQuantity={pagesQuantity}
                activeStyles={activeStyles}
                normalStyles={normalStyles}
                separatorStyles={separatorStyles}        
                currentPage={currentPage}
                onPageChange={setCurrentPage}
            >

                <Container align="center" justify="space-between" w="full" p={4}>
                    <Previous>
                        {<Icon><MdChevronLeft size={30}/></Icon>}
                    </Previous>
                    <PageGroup isInline align="center" />
                    <Next>
                        {<Icon><MdChevronRight size={30}/></Icon>}
                    </Next>
                </Container>
                <ProductGrid products={queryGraphQLProducts.slice(currentPage * 20, ((currentPage + 1) * 20) + 1)}/>
            </Paginator>
            <Footer />
        </Box>
        </>
    )
}

export default IndexPage;

export const mainPageQuery = graphql`
query {
    allContentfulProduct (filter: {name: {ne: "DummyProduct"}}) {
        nodes {
            contentful_id
            name
            prices
            moq
            discount
            categoryAndSubCategory {
                contentful_id
                categoryName
                subCategoryName
            }
            imageLowRes {
                contentful_id
                file {
                    url
                }
            }
            imageThumb {
                contentful_id
                file {
                    url
                }
            }
            packunitSizes {
                contentful_id
                juiceSizes
                weights
            }
        }
    }
    allContentfulCategory {
        distinct(field: categoryName)
    }
    allContentfulSlider {
        nodes {
            caption
            link {
                ... on ContentfulCategory {
                    categoryName
                    subCategoryName
                }
                ... on ContentfulProduct {
                    name
                }
            }
            subCatLink
            sliderImage {
                contentful_id
                file {
                    url
                }
            }
            sliderImageMobile {
                contentful_id
                file {
                    url
                }
            }
        }
    }
}
`;