import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { removeWS } from "../utils/utility_funcs";

const Instagram = ({ type, username, title, desc, image }) => (
    <Helmet>
        {username && <meta name="instagram:creator" content={username} />}
        <meta name="instagram:card" content={type} />
        <meta name="instagram:title" content={title} />
        <meta name="instagram:description" content={desc} />
        <meta name="instagram:image" content={image} />
        <meta name="instagram:image:alt" content={desc} />
    </Helmet>
)
Instagram.propTypes = {
    type: PropTypes.string,
    username: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}
Instagram.defaultProps = {
    type: 'summary_large_image',
    username: null,
}

const Facebook = ({ url, name, type, title, desc, image, locale }) => (
    <Helmet>
        {name && <meta property="fb:site_name" content={name} />}
        <meta property="fb:locale" content={locale} />
        <meta property="fb:url" content={url} />
        <meta property="fb:type" content={type} />
        <meta property="fb:title" content={title} />
        <meta property="fb:description" content={desc} />
        <meta property="fb:image" content={image} />
        <meta property="fb:image:alt" content={desc} />
    </Helmet>
)
Facebook.propTypes = {
    url: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
}
Facebook.defaultProps = {
    type: 'website',
    name: null,
}

const SEO = (props) => {
    const siteQuery = useStaticQuery(seoQuery);
    const site = siteQuery.site.siteMetadata;
    const categories = props.categories;

    // schema.org in JSONLD format
    // https://developers.google.com/search/docs/guides/intro-structured-data
    // https://schema.org/BreadcrumbList

    // Initial breadcrumb list
    const itemListElements = categories.map((category, i) => {
        return {
            "@type": "ListItem",
            "position": i+1,
            "name": category,
            "item": site.siteUrl + "/categories/" + removeWS(category)
        }
    });

    itemListElements.push({
        "@type": "ListItem",
        "position": 1,
        "name": "Sign Up",
        "item": site.siteUrl + "/login"
    });

    const breadcrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        // "name": 'SEO Breadcrumbs for VitalizeFresh categories',
        // "description": 'Breadcrumbs list',
        "itemListElement": itemListElements,
    }

    return (
        <>
            <Helmet title={site.title} titleTemplate={site.titleTemplate} >
                <html lang={site.siteLanguage} />
                <meta name="description" content={site.description} />
                {/* <meta name="url" content={site.siteUrl} /> */}
                <meta name="image" content={site.logo} />
                <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
            </Helmet>
            <Facebook
                desc={site.description}
                image={site.logo}
                title={site.title}
                type="website"
                url={site.siteUrl}
                locale={site.fbLanguage}
                name={site.facebook}
            />
            <Instagram
                title={site.title}
                image={site.logo}
                desc={site.description}
                username={site.instagram}
            />
        </>
    )
}

export default SEO;

const seoQuery = graphql`
    query SEO {
        site {
            siteMetadata {
                title
                titleTemplate
                description
                siteUrl
                author
                logo
                siteLanguage
                fbLanguage
                facebook
                instagram
            }
        }
    }
`